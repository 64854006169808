import { rem, transitions } from 'polished'
import styled, { keyframes, css } from 'styled-components'
import theme from 'styles/theme'
import { fadeInAnimation } from 'styles/animation'
import { Container as BaseContainer, buttonShadow } from 'styles/sharedStyle'

export const bounceFromTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-35vh);
  }
  80% {
    opacity: 1;
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0);
  }
`

const buttomCommonStyle = css`
  ${transitions(
    'border 0.2s',
    'box-shadow 0.2s',
    'color 0.2s',
    'background 0.2s'
  )}
  padding: ${rem(7.14)} ${rem(14.28)};
  border: none;
  font-size: ${rem(12)};
  color: ${theme.colors.white};
  stroke: ${theme.colors.white};
  font-family: BrownStd-Light;

  * {
    ${transitions(
      'border 0.2s',
      'box-shadow 0.2s',
      'color 0.2s',
      'background 0.2s'
    )}
    line-height: initial;
    color: inherit;
  }

  ${theme.mediaQueries.medium} {
    padding: ${rem(8.93)} ${rem(17.85)};
    font-size: ${rem(15.62)};
  }
`

const hoverButton = css`
  &:hover:not(:disabled), &:focus:not(:disabled) {
    ${buttonShadow}
    background-color: ${theme.colors.white};
    border-color:  ${theme.colors.white};
    color: ${theme.colors.pastelTeal};
    stroke: ${theme.colors.pastelTeal};

    * {
      color: inherit;
    }
  }
`

const activeButton = css`
  ${buttonShadow}
  background-color: ${theme.colors.teal};
  border-color: ${theme.colors.teal};
`

const activeInteractionButton = css`
  &:active:not(:disabled) {
    ${activeButton}
  }
`

const adjustContentWithIcon = css`
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    line-height: initial;
    margin-bottom: -2px;
  }

  & *,
  & path {
    stroke: inherit;
  }
`

export const Wrapper = styled.div`
  ${transitions('margin-top 0.5s', 'top 0.5s', 'opacity 0.5s')};
  top: ${(props) =>
    props?.scrollingTop ? rem((props?.top ?? 0) + 60) : rem(props?.top ?? 0)};
  position: sticky;
  position: -webkit-sticky;
  z-index: 4;
  width: 100%;
  margin: 0 auto;

  ${theme.mediaQueries.medium} {
    width: 100%;
    top: ${(props) =>
      props?.scrollingTop ? rem((props?.top ?? 0) + 70) : rem(props?.top ?? 0)};
    margin: 0 auto;
  }

  * {
    color: ${theme.colors.white};
  }

  ${(props) =>
    props?.hide &&
    `
    opacity: 0;
    pointer-events: none;
    animation: none;  
  `}
`

export const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;
  ${(props) => props?.mt && `margin-top: ${props.mt};`}
`

export const Container = styled(BaseContainer)`
${transitions('opacity 0.5s', 'background 0.75s')}

  animation: ${(props) =>
    props?.animation ??
    css`
      ${fadeInAnimation} 0.2s
    `};

  ${(props) =>
    props.bg && /background/gi.test(props.bg)
      ? props.bg
      : `
    background: ${props.bg};
  `}

  backdrop-filter: blur(20px);
  box-shadow: -3px -3px 150px 0px rgba(255,255,255,0.1) inset,3px 3px 150px 0px rgba(66,66,66,0.1) inset;
  display: flex;
  flex-flow: column nowrap;
  row-gap: ${rem(16)};
  width: 100%;
  padding: ${rem(17.8)};

  ${theme.mediaQueries.medium} {
    padding: ${rem(17.5)} ${rem(25)} ${rem(0)} ${rem(25)};
  }
  
  ${(props) =>
    props?.hide &&
    `
    opacity: 0;
    pointer-events: none;
    animation: none;
  `}
`

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.white};
`

export const IslandRow = styled.div`
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props?.hideOnMobile
      ? `
      display: none;

      ${theme.mediaQueries.medium} {
        display: flex;
      }
    `
      : 'display: flex;'}

  ${(props) => props?.noWrap && 'flex-wrap: nowrap;'}
  ${(props) =>
    props.extraStyle
      ? css`
          ${props.extraStyle}
        `
      : ''}
`

export const IslandRowGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: ${rem(10)};

  * {
    white-space: nowrap;
  }
`

export const Button = styled.button`
  ${buttomCommonStyle}
  ${buttonShadow}
  background-color: ${(props) =>
    props?.bg ? props.bg : theme.colors.pastelTeal};
  color: ${theme.colors.white};

  ${hoverButton}
  ${activeInteractionButton}
  
  ${(props) => props?.on && activeButton}
`

export const PhatonButton = styled.button`
  ${buttomCommonStyle}
  ${adjustContentWithIcon}
  background-color: transparent;
  color: ${theme.colors.white}; 
  
  ${hoverButton}
  ${activeInteractionButton}

  ${(props) =>
    props?.extraStyle
      ? css`
          ${props.extraStyle}
        `
      : ''}
`

export const IconButton = styled.button`
  ${buttomCommonStyle}
  ${buttonShadow}
  ${adjustContentWithIcon}
  background-color: ${(props) =>
    props?.bg ? props.bg : theme.colors.pastelTeal};
  border-radius: ${rem(24)};
  padding: ${rem(8)};
  width: ${rem(35.6)};
  height: ${rem(35.6)};

  svg {
    width: ${rem(11)};
  }

  path {
    stroke: ${theme.colors.white};
  }
  
  ${hoverButton}

  &:hover:not(:disabled) path, &:focus:not(:disabled) path {
    stroke: ${theme.colors.pastelTeal};
  }

  ${activeInteractionButton}

  &:active:not(:disabled) path {
    stroke: ${theme.colors.pastelTeal};
  }

  ${theme.mediaQueries.medium} {
    padding: ${rem(10)};
    width: ${rem(42)};
    height: ${rem(42)};
    transform: scale(1.05);

    svg {
      width: ${rem(14)};
      height: ${rem(16)};
      transform: scale(0.85);
    }
  }

  ${(props) =>
    props.on &&
    `
    background: ${theme.colors.teal};
  `}
`

export const LetterFilterButton = styled.button`
  ${buttomCommonStyle}
  padding: 0;
  width: ${rem(30)};
  height: ${rem(30)};
  font-family: BrownStd-Light, sans-serif;
  background-color: transparent;
  border: 1px solid ${theme.colors.white};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  span {
    font-size:${rem(16)};
  }

  ${hoverButton}
  ${activeInteractionButton}
  span{
    font-size:${rem(16)};
  }
  &:hover:not(:disabled), &:focus:not(:disabled) {
    ${buttonShadow}
    background: ${theme.colors.white};
    border-color: transparent;
    
    * {
      color: ${theme.colors.teal};
    }
  }

  &:active:not(:disabled) {
    ${buttonShadow}
    background: ${theme.colors.teal};

    * {
      color: ${theme.colors.white};
    }
  }

  ${(props) =>
    props?.on &&
    `
    background: ${theme.colors.teal};
    border-color: ${theme.colors.teal};
  `}

  &:disabled {
    opacity: 0.25;
    border-color: ${theme.colors.coallight};
    background: ${theme.colors.coallight};
    cursor: initial;
  }

  ${theme.mediaQueries.medium} {
    padding: 0;
  }
`

export default {
  bounceFromTop,
  Wrapper,
  Container,
  IslandRow,
  IslandRowGroup,
  Button,
  PhatonButton,
  IconButton,
  LetterFilterButton,
}
