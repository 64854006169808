import React from 'react'
import PropTypes from 'prop-types'
import { formatHeroDate } from 'utils/date'
import theme from 'styles/theme'
import {
  CampaignDateWrapper,
  CalendarIconWrapper,
  CalendarIcon,
  CampaignDate,
  Spacing38,
} from './Hero2.style'

const Hero2CampaignDate = ({ dateFrom, dateTo, iconColor, nodeLocale }) => {
  const campaignDateContent = `${formatHeroDate(
    dateFrom,
    nodeLocale
  )} - ${formatHeroDate(dateTo, nodeLocale)} ${new Date(dateTo).getFullYear()}`

  return (
    <div>
      <Spacing38 />
      <CampaignDateWrapper>
        <CalendarIconWrapper>
          <CalendarIcon color={iconColor} />
        </CalendarIconWrapper>
        <CampaignDate>{campaignDateContent}</CampaignDate>
      </CampaignDateWrapper>
    </div>
  )
}

Hero2CampaignDate.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  iconColor: PropTypes.string,
  nodeLocale: PropTypes.string,
}

Hero2CampaignDate.defaultProps = {
  dateFrom: '',
  dateTo: '',
  iconColor: theme.colors.teal,
  nodeLocale: 'en',
}

export default Hero2CampaignDate
